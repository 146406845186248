import React, { useState, useEffect } from 'react'
import { CountryDropdown } from 'react-country-region-selector'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { businessCategoryToTypesMapping } from '../utils/businessCategoryToTypes'
import PostCodeChecker from './PostCodeChecker'
import { processPayment, getProducts } from '../features/payments/paymentsSlice'
import { getCurrencyForCountry } from './utility'
import { isPasswordSecure, getPasswordStrength } from '../utils/passwordUtils'
import SquarePaymentButton from './SquarePaymentButton'
import AutoFillButton from './AutoFillButton'
import { useSelector, useDispatch } from 'react-redux'

const PaymentForm = ({ onSubmit, formData, setFormData }) => {
  const dispatch = useDispatch()
  const { productId } = useParams() // Get productId from URL
  const { products } = useSelector((state) => state.payments)

  const selectedProduct = products ? products.find((product) => product.id === productId) : null

  const [isValidPostCode, setIsValidPostCode] = useState(true)
  const [showOtherBusinessInput, setShowOtherBusinessInput] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [passwordMatchError, setPasswordMatchError] = useState('')
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  console.log('Rendering PaymentForm')

  const {
    businessName,
    name,
    email,
    password,
    password2,
    accessPass,
    postcode,
    businessType,
    businessCategory,
    otherBusinessType,
    country,
    agentReferralCode,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  } = formData

  function formatPostcode(p) {
    // Remove all spaces and convert to uppercase
    p = p.replace(/\s/g, '').toUpperCase()

    // UK postcode regex
    var postcodeRegEx = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/

    // If it's a complete postcode, format it
    if (postcodeRegEx.test(p)) {
      return p.slice(0, -3) + ' ' + p.slice(-3)
    }

    // If it's not a complete postcode yet, just return the uppercase version
    return p
  }

  const onChange = (e) => {
    let { name, value } = e.target

    if (name === 'businessType') {
      setShowOtherBusinessInput(value === 'Other')
    }

    if (name === 'postcode') {
      value = formatPostcode(value)
    }

    if (name === 'password') {
      if (!isPasswordSecure(value)) {
        setPasswordError('Password must be at least 8 characters long, contain uppercase and lowercase letters and numbers.')
      } else {
        setPasswordError('')
      }
      setPasswordStrength(getPasswordStrength(value))
    }

    if (name === 'password2') {
      if (value !== formData.password) {
        setPasswordMatchError('Passwords do not match')
      } else {
        setPasswordMatchError('')
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validatePostCode = (postcode) => {
    const regex = new RegExp(/^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i)
    return regex.test(postcode)
  }

  const isValidForm = () => {
    console.log('Validating form...') // Debug log
    if (formData.password !== formData.password2) {
      toast.error('Passwords do not match')
      return false
    }
    if (!isPasswordSecure(formData.password)) {
      toast.error('Password must be at least 8 characters long, contain uppercase and lowercase letters and numbers.')
      return false
    }
    // Add any other form validation checks here
    console.log('Form is valid') // Debug log
    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('Form submitted')

    if (isValidForm()) {
      console.log('Form is valid')
      try {
        console.log('Submitting form data...')
        await onSubmit(formData)
        console.log('Form data submitted successfully')
        setIsFormSubmitted(true)
        console.log('isFormSubmitted set to true')
        document.getElementById('payment_modal').checked = true
        console.log('Payment modal opened')
      } catch (error) {
        console.error('Form submission error:', error)
        toast.error('An error occurred while submitting the form. Please try again.')
      }
    } else {
      console.log('Form is not valid')
    }
  }

  const handlePaymentSuccess = (token) => {
    console.log('Payment success, token:', token)
    if (isValidPostCode) {
      const currency = getCurrencyForCountry(formData.country)

      try {
        dispatch(
          processPayment({
            productId, // Use the productId from URL
            currency,
            paymentToken: token,
            email: formData.email,
            // Add any other specific fields needed for payment processing
          })
        )
        toast.success('Registration and payment completed successfully!')
        // Optionally, redirect to a success page or clear the form
      } catch (error) {
        console.error('Payment submission error:', error)
        toast.error('An error occurred while processing the payment. Please try again.')
      }
    } else {
      toast.error('Invalid UK Postcode')
    }
  }

  const handleCountryChange = (val) => {
    // setSelectedCountry(val)
    setFormData((prevState) => ({
      ...prevState,
      country: val,
    }))
  }

  const handleAutoFill = (testData) => {
    setFormData((prevState) => ({
      ...prevState,
      ...testData,
    }))
    // Trigger password validation
    onChange({ target: { name: 'password', value: testData.password } })
    onChange({ target: { name: 'password2', value: testData.password2 } })
  }

  const formatPrice = (amount) => {
    return (parseInt(amount) / 100).toFixed(2)
  }

  const getProductAmount = () => {
    if (selectedProduct && selectedProduct.subscriptionPlanData) {
      const variation = selectedProduct.subscriptionPlanData.subscriptionPlanVariations[0]
      if (variation) {
        const recurringPhase = variation.subscriptionPlanVariationData.phases.find((phase) => phase.ordinal === '1')
        if (recurringPhase) {
          return recurringPhase.pricing.priceMoney.amount
        }
      }
    }
    return 0
  }

  const getProductCurrency = () => {
    if (selectedProduct && selectedProduct.subscriptionPlanData) {
      const variation = selectedProduct.subscriptionPlanData.subscriptionPlanVariations[0]
      if (variation) {
        const recurringPhase = variation.subscriptionPlanVariationData.phases.find((phase) => phase.ordinal === '1')
        if (recurringPhase) {
          return recurringPhase.pricing.priceMoney.currency
        }
      }
    }
    return 'GBP'
  }

  return (
    <>
      <AutoFillButton onAutoFill={handleAutoFill} />
      <form onSubmit={handleSubmit}>
        <div className='relative mb-2'>
          <input type='text' className='global-input' placeholder='' id='name' name='name' value={name} onChange={onChange} required />
          <label htmlFor='name' className='global-form-label'>
            Your name
          </label>
        </div>
        <div className='relative mb-2'>
          <input
            type='text'
            className='global-input lh-30'
            placeholder=''
            id='businessName'
            name='businessName'
            value={businessName}
            onChange={onChange}
            required
          />
          <label htmlFor='Business name' className='global-form-label'>
            Your Business Name
          </label>
        </div>

        <div className='relative mb-2'>
          <input type='email' placeholder='' className='global-input' id='email' name='email' value={email} onChange={onChange} required />
          <label htmlFor='name' className='global-form-label'>
            Your email address
          </label>
        </div>
        {/* COUNTRY*/}
        <div className='relative mb-2'>
          <CountryDropdown
            value={country}
            onChange={handleCountryChange}
            className='global-input focus:outline-none bg-gray-300'
            defaultOptionLabel='Select country'
            priorityOptions={['GB', 'US', 'CA']}
            showDefaultOption={true}
            valueType='short'
            placeholder=''
            disabled
          />
          <label htmlFor='businessCategory' className={`absolute left-3 top-1   text-gray-400 pointer-events-none text-sm`}>
            Your Country
          </label>

          <div className='absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none'></div>
        </div>

        {country === 'GB' && (
          <>
            <p>
              To check the eligibility of your postcode,{' '}
              <label htmlFor='my-modal-6' className=' link'>
                here
              </label>
            </p>
            <div className='relative mb-2'>
              <input
                type='text'
                maxLength='7'
                className='global-input'
                placeholder=''
                id='postcode'
                name='postcode'
                value={postcode}
                onChange={onChange}
                required
              />
              <label htmlFor='postcode' className='global-form-label'>
                Your Postcode
              </label>
            </div>
            <div className='relative mb-2'>
              <select
                className='global-input focus:outline-none'
                id='businessCategory'
                name='businessCategory'
                value={businessCategory}
                onChange={onChange}
                required>
                <option value=''>Please select an industry sector</option>
                <option value='Entertainment'>Entertainment</option>
                <option value='Hair/Beauty'>Hair/Beauty</option>
                <option value='Health/Fitness'>Health/Fitness</option>
                <option value='High Street Shop'>High Street Shop</option>
                <option value='High Street Shop/Clothing'>High Street Shop/Clothing</option>
                <option value='High Street Shop/DIY-Building'>High Street Shop/DIY-Building</option>
                <option value='High Street Shop/Food & Drink'>High Street Shop/Food & Drink</option>
                <option value='High Street Shop/Gardening'>High Street Shop/Gardening</option>
                <option value='High Street Shop/Health & Wellness'>High Street Shop/Heath & Wellness</option>
                <option value='High Street Shop/Motoring'>High Street Shop/Motoring</option>
                <option value='Places to Stay'>Places to Stay</option>
                <option value='Pubs/Bars'>Pubs/Bars</option>
                <option value='Restaurants/Cafes'>Restaurants/Cafes</option>
                <option value='Takeaways'>Takeaways</option>
              </select>

              <label htmlFor='businessCategory' className={`absolute left-3 top-1   text-gray-400 pointer-events-none text-sm`}>
                Your Industry Sector
              </label>

              <div className='absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none'></div>
            </div>

            <div className='relative mb-2'>
              <select
                className='global-input focus:outline-none'
                id='businessType'
                name='businessType'
                value={businessType}
                onChange={onChange}
                required>
                <option value=''>{businessCategory ? 'Please select a Business type' : 'Please select an industry sector first'}</option>
                {businessCategory &&
                  businessCategoryToTypesMapping[businessCategory].map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
              </select>
              <label htmlFor='businessCategory' className={`absolute left-3 top-1   text-gray-400 pointer-events-none text-sm`}>
                Your Business Type
              </label>

              <div className='absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none'></div>
            </div>
            {showOtherBusinessInput && (
              <div className='relative mb-2'>
                <input
                  type='text'
                  className='global-input'
                  placeholder=''
                  id='otherBusinessType'
                  name='otherBusinessType'
                  value={otherBusinessType}
                  onChange={onChange}
                />
                <label htmlFor='Business name' className='global-form-label'>
                  Enter Other Business Type
                </label>
              </div>
            )}
          </>
        )}
        <div className='relative mb-2'>
          <input
            type='password'
            className='global-input'
            placeholder=''
            id='password'
            name='password'
            value={password}
            onChange={onChange}
            required
          />
          <label htmlFor='password' className='global-form-label'>
            Your password
          </label>
        </div>
        {passwordError && <p className='text-red-500 text-xs mt-1'>{passwordError}</p>}
        <div className='mt-1 h-2 w-full bg-gray-200 rounded-full'>
          <div
            className={`h-full rounded-full ${
              passwordStrength === 0
                ? 'bg-red-500'
                : passwordStrength === 1
                ? 'bg-orange-500'
                : passwordStrength === 2
                ? 'bg-yellow-500'
                : passwordStrength === 3
                ? 'bg-lime-500'
                : passwordStrength === 4
                ? 'bg-green-500'
                : 'bg-green-600'
            }`}
            style={{ width: `${passwordStrength * 20}%` }}></div>
        </div>
        <p className='text-xs mt-1 mb-2'>
          {passwordStrength === 0
            ? 'Very weak'
            : passwordStrength === 1
            ? 'Weak'
            : passwordStrength === 2
            ? 'Fair'
            : passwordStrength === 3
            ? 'Good'
            : passwordStrength === 4
            ? 'Strong'
            : 'Very strong'}
        </p>

        <div className='relative mb-2'>
          <input
            type='password'
            className='global-input'
            placeholder=''
            id='password2'
            name='password2'
            value={password2}
            onChange={onChange}
            required
          />
          <label htmlFor='Business name' className='global-form-label'>
            Confirm Your password
          </label>
          {passwordMatchError && <p className='text-red-500 text-xs mt-1'>{passwordMatchError}</p>}
        </div>
        <div className='relative mb-2'>
          <input type='password' className='global-input' placeholder='' id='accessPass' name='accessPass' value={accessPass} onChange={onChange} />
          <label htmlFor='Business name' className='global-form-label'>
            Access password
          </label>
        </div>
        <div className='relative mb-2'>
          <input
            type='text'
            className='global-input'
            placeholder=''
            id='agentReferralCode'
            name='agentReferralCode'
            value={agentReferralCode}
            onChange={onChange}
          />
          <label htmlFor='Business name' className='global-form-label'>
            Agent code (optional)
          </label>
        </div>

        {/* Middle Content */}
        <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
          <button
            type='submit'
            className='btn-primary flex p-6 rounded-md text-white w-full justify-center items-center space-x-4 font-sans font-bold shadow-lg px-9 hover:bg-opacity-90 hover:shadow-lg border transition hover:-translate-y-0.5 duration-150 mb-20'>
            <span>Submit and Proceed to Payment</span>
            <img src='images/next-arrow.svg' alt='' />
          </button>
        </div>
      </form>

      {/* Hidden checkbox for modal control */}
      <input type='checkbox' id='payment_modal' className='modal-toggle' />

      {/* Payment Modal */}
      <div className='modal' role='dialog'>
        <div className='modal-box'>
          <h3 className='text-lg font-bold'>Complete Payment</h3>
          <SquarePaymentButton
            productId={productId}
            onPaymentSuccess={handlePaymentSuccess}
            amount={getProductAmount()}
            currency={getProductCurrency()}
          />
          <div className='modal-action'>
            <label htmlFor='payment_modal' className='btn'>
              Close
            </label>
          </div>
        </div>
      </div>

      {/* Modal Popup */}
      {/* Hidden Checkbox for Modal Control */}
      <input type='checkbox' id='my-modal-6' className='modal-toggle' />
      <div className='modal' role='dialog'>
        <div className='modal-box'>
          <PostCodeChecker /> {/* Embed the PostCodeChecker component */}
          <div className='modal-action'>
            <label htmlFor='my-modal-6' className='btn'>
              Close!
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentForm
