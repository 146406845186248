import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { processPayment } from '../features/payments/paymentsSlice' // Redux action
import { CountryDropdown } from 'react-country-region-selector'
import toast from 'react-hot-toast'

const SquarePaymentButton = ({ onPaymentSuccess, amount, currency, productId }) => {
  const dispatch = useDispatch()
  const [payments, setPayments] = useState(null)
  const [card, setCard] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState('')
  const { loading, error, success, products } = useSelector((state) => state.payments)

  const { REACT_APP_SQUARE_APPLICATION_ID, REACT_APP_SQUARE_LOCATION_ID } = process.env

  console.log('Square Application ID:', REACT_APP_SQUARE_APPLICATION_ID)
  console.log('Square Location ID:', REACT_APP_SQUARE_LOCATION_ID)

  const { registrationDetails } = useSelector((state) => state.auth)

  const [cardholderDetails, setCardholderDetails] = useState({
    cardholderName: '',
    addressLine1: '',
    addressLine2: '',
    locality: '',
    administrativeDistrictLevel1: '',
    country: 'GB', // Default to GB
  })

  const [isValidPostCode, setIsValidPostCode] = useState(true)

  // New state to store the matched product
  const [matchedProduct, setMatchedProduct] = useState(null)
  console.log('matchedProduct', matchedProduct)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCardholderDetails((prev) => ({ ...prev, [name]: value }))
  }

  const handleCountryChange = (val) => {
    setCardholderDetails((prev) => ({ ...prev, country: val }))
  }

  useEffect(() => {
    const loadSquareSDK = async () => {
      const script = document.createElement('script')
      script.src = 'https://web.squarecdn.com/v1/square.js'
      // script.src = 'https://sandbox.web.squarecdn.com/v1/square.js'
      script.async = true
      script.onload = () => initializeSquare()
      document.body.appendChild(script)
    }

    const initializeSquare = async () => {
      try {
        const payments = window.Square.payments(REACT_APP_SQUARE_APPLICATION_ID, REACT_APP_SQUARE_LOCATION_ID)
        // Ensure payments is defined before proceeding
        if (!payments) {
          throw new Error('Square payments object is undefined')
        }
        setPayments(payments)

        const cardInstance = await payments.card()
        await cardInstance.attach('#card-container')
        setCard(cardInstance)
      } catch (error) {
        console.error('Error initializing Square Payments:', error)
      }
    }

    loadSquareSDK()
  }, [])

  useEffect(() => {
    // Find the matching product
    const findMatchingProduct = () => {
      const matched = products.find((product) =>
        product.subscriptionPlanData.subscriptionPlanVariations.some((variation) => variation.id === productId)
      )
      setMatchedProduct(matched)
    }

    if (products && productId) {
      findMatchingProduct()
    }
  }, [products, productId])

  const handlePayment = async (e) => {
    e.preventDefault()
    setPaymentStatus('Processing payment...')
    try {
      if (!card || !payments) {
        throw new Error('Card payment method not initialized')
      }
      const tokenResult = await card.tokenize()
      if (tokenResult.status === 'OK') {
        // Get postal code from the tokenization result
        const postalCode = tokenResult.details?.billing?.postalCode

        // Add the verifyBuyer call here with the postal code from Square
        const verificationDetails = {
          amount: '1.00',
          billingContact: {
            givenName: cardholderDetails.cardholderName.split(' ')[0],
            familyName: cardholderDetails.cardholderName.split(' ').slice(1).join(' '),
            email: registrationDetails.email,
            phone: registrationDetails.phone,
            address: {
              address_line_1: cardholderDetails.addressLine1,
              address_line_2: cardholderDetails.addressLine2 || undefined,
              locality: cardholderDetails.locality,
              administrative_district_level_1: cardholderDetails.administrativeDistrictLevel1,
              postal_code: postalCode, // Use the postal code from Square's form
              country: cardholderDetails.country,
            },
          },
          currencyCode: currency,
          intent: 'STORE',
        }

        console.log('verificationDetails', verificationDetails)

        const verificationToken = await payments.verifyBuyer(tokenResult.token, verificationDetails)

        const squareCustomerId = registrationDetails.subscription.squareCustomerID
        const addressDetails = registrationDetails.address
        const customerName = registrationDetails.name

        if (!squareCustomerId) {
          throw new Error('Square Customer ID is missing. Please contact support.')
        }

        const paymentData = {
          token: tokenResult.token,
          amount: amount,
          currency: currency,
          squareCustomerId: squareCustomerId,
          productId: productId,
          productData: matchedProduct, // Include the entire matched product object
          addressDetails: addressDetails,
          customerName: customerName,
          customerId: registrationDetails.customerId,
          planId: registrationDetails.planId,
          cardholderName: cardholderDetails.cardholderName,
          billingAddress: {
            addressLine1: cardholderDetails.addressLine1,
            addressLine2: cardholderDetails.addressLine2,
            locality: cardholderDetails.locality,
            administrativeDistrictLevel1: cardholderDetails.administrativeDistrictLevel1,
            country: cardholderDetails.country,
          },
          verificationToken: verificationToken,
        }
        console.log('Payment data being sent:', paymentData)
        onPaymentSuccess(paymentData)
      } else {
        throw new Error(tokenResult.errors[0].message)
      }
    } catch (error) {
      console.error('Error during payment:', error)
      setPaymentStatus(`Payment failed: ${error.message}. Please try again or contact support.`)
    }
  }

  return (
    <div>
      <form id='payment-form' onSubmit={handlePayment}>
        <div className='relative mb-2'>
          <input
            type='text'
            className='global-input'
            placeholder=''
            id='cardholderName'
            name='cardholderName'
            value={cardholderDetails.cardholderName}
            onChange={handleInputChange}
            required
          />
          <label htmlFor='cardholderName' className='global-form-label'>
            Cardholder Name
          </label>
        </div>
        <div className='relative mb-2'>
          <input
            type='text'
            className='global-input'
            placeholder=''
            id='addressLine1'
            name='addressLine1'
            value={cardholderDetails.addressLine1}
            onChange={handleInputChange}
            required
          />
          <label htmlFor='addressLine1' className='global-form-label'>
            Address Line 1
          </label>
        </div>
        <div className='relative mb-2'>
          <input
            type='text'
            className='global-input'
            placeholder=''
            id='addressLine2'
            name='addressLine2'
            value={cardholderDetails.addressLine2}
            onChange={handleInputChange}
          />
          <label htmlFor='addressLine2' className='global-form-label'>
            Address Line 2
          </label>
        </div>
        <div className='relative mb-2'>
          <input
            type='text'
            className='global-input'
            placeholder=''
            id='locality'
            name='locality'
            value={cardholderDetails.locality}
            onChange={handleInputChange}
            required
          />
          <label htmlFor='locality' className='global-form-label'>
            City
          </label>
        </div>
        <div className='relative mb-2'>
          <input
            type='text'
            className='global-input'
            placeholder=''
            id='administrativeDistrictLevel1'
            name='administrativeDistrictLevel1'
            value={cardholderDetails.administrativeDistrictLevel1}
            onChange={handleInputChange}
            required
          />
          <label htmlFor='administrativeDistrictLevel1' className='global-form-label'>
            State/Province
          </label>
        </div>
        <div className='relative mb-2'>
          <CountryDropdown
            value={cardholderDetails.country}
            onChange={handleCountryChange}
            className='global-input focus:outline-none'
            defaultOptionLabel='Select country'
            priorityOptions={['GB', 'US', 'CA']}
            showDefaultOption={true}
            valueType='short'
            placeholder=''
          />
          <label htmlFor='country' className={`absolute left-3 top-1 text-gray-400 pointer-events-none text-sm`}>
            Country
          </label>
          <div className='absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none'></div>
        </div>
        <div id='card-container' className='mb-4'></div>
        <button
          type='submit'
          disabled={loading}
          className='btn-primary flex p-6 rounded-md text-white w-full justify-center items-center space-x-4 font-sans font-bold shadow-lg px-9 hover:bg-opacity-90 hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
          Pay {amount / 100} {currency} and Start Subscription
        </button>
      </form>
      {paymentStatus && <p className='mt-4'>{paymentStatus}</p>}
      {error && <p className='mt-4 text-red-500'>Error: {error}</p>}
      {success && <p className='mt-4 text-green-500'>Payment successful and subscription started!</p>}
      <div id='payment-status-container'></div>
      <p className='text-sm text-gray-600 mt-2'>
        If you're experiencing issues, please ensure any ad blockers or content blockers are disabled for this site.
      </p>
      {matchedProduct && <p className='mt-4 text-sm text-gray-600'>Selected plan: {matchedProduct.subscriptionPlanData.name}</p>}
    </div>
  )
}

export default SquarePaymentButton
