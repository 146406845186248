import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const PAYMENTS_URL = `${REACT_APP_SERVER_URL}/api/payments`

// Process payment using the token from Square
const processPayment = async (paymentData) => {
  console.log('Payment data being sent:', paymentData)
  try {
    console.log('Payment data being sent:', paymentData)
    const response = await api.post(PAYMENTS_URL + '/process-payment', paymentData)
    return response.data
  } catch (error) {
    console.error('Error in processPayment:', error)
    throw error
  }
}

const getProducts = async () => {
  try {
    console.log('Getting products')
    const products = await api.get(`${PAYMENTS_URL}/products`)
    console.log('products returned')
    return products.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const paymentsService = {
  getProducts,
  processPayment,
}

export default paymentsService
