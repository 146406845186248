import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { register, reset } from '../features/auth/authSlice'
import { resetPurchaseState } from '../features/subscription/subscriptionSlice'
import { processPayment, getProducts } from '../features/payments/paymentsSlice'
import Spinner from '../components/Spinner'
import PaymentForm from '../components/PaymentForm'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'

// Remove the Stripe-related import

function Purchase() {
  const [formData, setFormData] = useState({
    businessName: '',
    name: '',
    email: '',
    password: '',
    password2: '',
    accessPass: '',
    postcode: '',
    country: 'GB',
    businessType: '',
    businessCategory: '',
    otherBusinessType: '',
    agentReferralCode: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  })

  console.log(formData)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { productId } = useParams()
  const location = useLocation()

  const { products } = useSelector((state) => state.payments)
  console.log(products, 'products')

  const selectedProduct = products.find((product) => product.id === productId)

  const { user, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isError && message.message === 'A business of this type already exists in the postcode area.') {
      document.getElementById('my_modal_6').checked = true
      toast.error(message.message)
    }

    if (isError && message.message !== 'A business of this type already exists in the postcode area.') {
      toast.error(message.message)
    }

    dispatch(reset())
  }, [isError, isSuccess, message, navigate, dispatch])

  useEffect(() => {
    // Capture referral code and UTM parameters from URL
    const params = new URLSearchParams(location.search)
    const ref = params.get('ref')
    const utmFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

    const newFormData = { ...formData }
    if (ref) {
      newFormData.agentReferralCode = ref
    }

    utmFields.forEach((field) => {
      const value = params.get(field)
      if (value) {
        newFormData[field] = value
      }
    })

    setFormData(newFormData)

    dispatch(getProducts())

    return () => {
      dispatch(resetPurchaseState())
    }
  }, [dispatch, location])

  const onSubmit = async (userData) => {
    try {
      await dispatch(register(userData))
      // Handle successful registration
      // You may want to initiate the checkout session here if needed
      // await dispatch(initiateCheckoutSession(userData))
    } catch (error) {
      console.error('Error registering user: ', error)
      toast.error('Registration failed. Please try again.')
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {/* Modal controlled by hidden checkbox */}
      <input type='checkbox' id='my_modal_6' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>Registration Unsuccessful</h3>
          <p className='py-4'>
            We're sorry, but a business of this type already exists in the specified postcode area. Due to our exclusivity policy, we're unable to
            register another business of the same type in this location. We apologize for the inconvenience and appreciate your understanding.
          </p>
          <div className='modal-action'>
            <label htmlFor='my_modal_6' className='btn'>
              Close!
            </label>
          </div>
        </div>
      </div>
      {/* Global Container */}
      <div className='flex items-center justify-center'>
        {/* Card Container */}
        <div className=' m-3 relative flex flex-col space-y-10 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0 md:mb-8'>
          {/* Left Side */}
          <div className='p-6 md:p-20 w-full md:w-3/5'>
            {/* Top Content */}

            <h2 className='font-mono mb-5 text-4xl font-bold text-left ml-1'>Register now and try for free!</h2>

            <p className='max-w-sm mb-12 font-sans font-light text-gray-600 text-left'>Register now and experience our 7 day free trial</p>
            {/* Form */}
            <PaymentForm formData={formData} setFormData={setFormData} onSubmit={onSubmit} productId={productId} />
          </div>
          {/* Right Side */}
          <div alt='' className='relative w-2/5 hidden md:block bg-custom-image rounded-r-xl'>
            <div className='absolute inset-0 bg-black bg-opacity-50 rounded-r-xl' />
            <div className='relative pt-8 px-6'>
              <h1 className='text-4xl font-bold text-white'>You're Almost There!</h1>
              <p className='text-white text-xl pt-2'>Create your account to start your 7-day trial and create your first loyalty campaign</p>
              <img className='pt-12' src='/images/mockup.png' alt='' />
              <p className='text-white font-bold text-lg mt-4 '>Your Plan: </p>
              <div className='w-full h-20 border-white border rounded-lg mt-4'>
                <div className='flex justify-between p-3'>
                  <div className='text-left'>
                    {' '}
                    <p className='text-white font-bold'>{selectedProduct ? selectedProduct.name : 'No product selected'}</p>
                    <p className='text-white'>7 day free trial</p>
                  </div>
                  <div className='text-right'>
                    <p className='text-white font-bold'>{selectedProduct ? `$${selectedProduct.price}` : 'No product selected'}</p>
                    <p className='text-white'>
                      {selectedProduct
                        ? selectedProduct.name === 'Monthly Plan'
                          ? 'Billed Monthly'
                          : selectedProduct.name === 'Yearly Plan'
                          ? 'Billed Yearly'
                          : ''
                        : ''}
                    </p>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div
                    className='tooltip  tooltip-bottom mt-8 cursor-pointer'
                    data-tip='Our Loyalty Club app welcomes businesses of all types, from hair salons to eateries, creating a robust ecosystem of diverse services.'>
                    <p className='text-white font-bold flex items-center text-left space-x-2'>
                      <IoCheckmarkCircleOutline />
                      &nbsp; Dynamic Business Integration
                    </p>
                  </div>
                  <div
                    className='tooltip mt-4  tooltip-bottom cursor-pointer'
                    data-tip='Partners can customize their loyalty programs, offering the flexibility to design campaigns that best align with their business ethos.'>
                    <p className='text-white font-bold flex items-center text-left space-x-2 text-left'>
                      <IoCheckmarkCircleOutline /> &nbsp;Tailor-Made Loyalty Campaigns
                    </p>
                  </div>
                  <div
                    className='tooltip mt-4  tooltip-bottom cursor-pointer'
                    data-tip='Our platform assigns unique QR codes to partners and customers alike, facilitating a straightforward process for joining and utilizing loyalty programs.'>
                    <p className='text-white font-bold text-left flex items-center space-x-2'>
                      <IoCheckmarkCircleOutline />
                      &nbsp;Seamless QR Code System
                    </p>
                  </div>
                  <div
                    className='tooltip mt-4  tooltip-bottom cursor-pointer'
                    data-tip='Customers have the ability to scan partner QR codes, view their campaigns, and join with just a few clicks, providing an interactive and user-friendly experience.'>
                    <p className='text-white font-bold flex text-left items-center space-x-2'>
                      <IoCheckmarkCircleOutline /> &nbsp;Comprehensive Customer Portal
                    </p>
                  </div>
                  <div
                    className='tooltip mt-4  tooltip-bottom cursor-pointer'
                    data-tip='With personal customer QR codes, reward collection and redemption has never been easier, boosting customer engagement and satisfaction.'>
                    <p className='text-white font-bold text-left flex items-center space-x-2'>
                      <IoCheckmarkCircleOutline />
                      &nbsp;Hassle-Free Rewards
                    </p>
                  </div>
                  <div
                    className='tooltip mt-4  tooltip-bottom cursor-pointer'
                    data-tip='Allow partners to efficiently set up, manage, and monitor their loyalty programs, ensuring maximum reach and effectiveness.'>
                    <p className='text-white font-bold text-left flex items-center space-x-2'>
                      <IoCheckmarkCircleOutline /> &nbsp;Efficient Campaign Management
                    </p>
                  </div>
                  <div
                    className='tooltip mt-4  tooltip-bottom cursor-pointer'
                    data-tip='Partners can effortlessly apply stamps by scanning customer QR codes, ensuring a smooth and quick transaction process.'>
                    <p className='text-white font-bold text-left flex items-center space-x-2'>
                      <IoCheckmarkCircleOutline />
                      &nbsp;Instant Stamp Application
                    </p>
                  </div>
                  <div
                    className='tooltip tooltip-bottom mt-4 cursor-pointer'
                    data-tip='Unlock your potential with our platform! Build an expansive customer list, engage your audience with targeted messaging, and drive your business growth with exclusive promotions.'>
                    <p className='text-white font-bold text-left flex items-center space-x-2'>
                      <IoCheckmarkCircleOutline />
                      &nbsp;Empower Your Business Communication
                    </p>
                  </div>
                  <div
                    className='tooltip mt-4 tooltip-bottom cursor-pointer'
                    data-tip='Through effective loyalty campaigns, enhance customer engagement and boost repeat business, ultimately driving business growth and profitability.'>
                    <p className='text-white font-bold text-left flex items-center space-x-2'>
                      <IoCheckmarkCircleOutline />
                      &nbsp;Increase Customer Retention
                    </p>
                  </div>
                  <div
                    className='tooltip mt-4  tooltip-bottom cursor-pointer'
                    data-tip='With easy access to various campaigns, customers can effortlessly interact with businesses, promoting a seamless and engaging shopping experience.'>
                    <p className='text-white font-bold text-left flex items-center space-x-2'>
                      <IoCheckmarkCircleOutline />
                      &nbsp;Innovative Customer Engagement
                    </p>
                  </div>
                  <div
                    className='tooltip mt-4  tooltip-bottom cursor-pointer'
                    data-tip='Businesses can tailor their reward offerings, fostering unique customer experiences and differentiating themselves from competitors.'>
                    <p className='text-white font-bold text-left flex items-center space-x-2'>
                      <IoCheckmarkCircleOutline /> &nbsp;Unique Reward Offerings
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Purchase
