import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import subscriptionService from './subscriptionService'

const initialState = {
  products: [],
  TEDSPackages: [],
  subscription: null,
  clientSecret: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  errorMessage: '',
  manageSubscriptionLink: '',
  emailResent: '',
  resendError: '',
  accountLinkUrl: '',
  stripeAccount: '',
}

// * initite checkout session during registration
export const initiateCheckoutSession = createAsyncThunk('subscription/createSession', async (checkoutData, thunkAPI) => {
  try {
    return await subscriptionService.initiateCheckoutSession(checkoutData)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *Logged in member checkout session
export const existingMemberCheckoutSession = createAsyncThunk('subscription/existingMemberCheckout', async (checkoutData, thunkAPI) => {
  try {
    return await subscriptionService.existingMemberCheckoutSession(checkoutData)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// * verify token

export const verifyToken = createAsyncThunk('subscription/verifyToken', async (verificationToken, thunkAPI) => {
  try {
    thunkAPI.dispatch(resetVerificationState())
    return await subscriptionService.verifyToken(verificationToken)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// * resend verification email

export const resendVerificationEmail = createAsyncThunk('subscription/resendEmail', async (clientID, thunkAPI) => {
  try {
    return await subscriptionService.resendVerificationEmail(clientID)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// * manage subscription

export const manageSubscription = createAsyncThunk('subscription/manageSubscription', async (stripeCustomerID, thunkAPI) => {
  try {
    return await subscriptionService.manageSubscription(stripeCustomerID)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// * initiate stripe connect (for partner to link bank account details)

export const initiateStripeConnect = createAsyncThunk('subscription/initiateConnect', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await subscriptionService.initiateStripeConnect(token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// * initiate stripe connect (for partner to link bank account details)

export const getPartnerStripeAccount = createAsyncThunk('subscription/getPartnerStripeAccount', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await subscriptionService.getPartnerStripeAccount(token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
      state.accountLinkUrl = null
    },
    logout: (state) => {
      state.products = []
      state.TEDSPackages = []
      state.subscription = null
      state.clientSecret = null
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.message = ''
      state.errorMessage = ''
      state.manageSubscriptionLink = ''
      state.emailResent = ''
      state.resendError = ''
      state.accountLinkUrl = ''
      state.stripeAccount = ''
    },
    resetVerificationState: (state) => {
      state.isVerifying = false
      state.isVerified = false
      state.verifyError = ''
    },
    resetResendEmailState: (state) => {
      state.isResending = false
      state.isResendSuccessful = false
      state.resendError = ''
    },
    resetPurchaseState: (state) => {
      state.session = null
      state.TEDSPackages = []
      state.products = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initiateCheckoutSession.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(initiateCheckoutSession.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.session = action.payload
    })
    builder.addCase(initiateCheckoutSession.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(existingMemberCheckoutSession.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(existingMemberCheckoutSession.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.session = action.payload
    })
    builder.addCase(existingMemberCheckoutSession.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(verifyToken.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(verifyToken.fulfilled, (state) => {
      state.isLoading = false
      state.isSuccess = true
      state.isError = false
    })
    builder.addCase(verifyToken.rejected, (state, action) => {
      state.isError = true
      state.message = action.payload
      state.isLoading = false
    })
    builder.addCase(resendVerificationEmail.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(resendVerificationEmail.fulfilled, (state) => {
      state.isLoading = false
      state.emailResent = true
    })
    builder.addCase(resendVerificationEmail.rejected, (state, action) => {
      state.isError = true
      state.emailResent = false
      state.message = action.payload
    })
    builder.addCase(manageSubscription.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(manageSubscription.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.manageSubscriptionLink = action.payload
    })
    builder.addCase(manageSubscription.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.errorMessage = action.payload
    })
    builder
      .addCase(initiateStripeConnect.pending, (state) => {
        state.isLoading = true
      })
      .addCase(initiateStripeConnect.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.accountLinkUrl = action.payload.url
      })
      .addCase(initiateStripeConnect.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
      })
    builder
      .addCase(getPartnerStripeAccount.pending, (state) => {})
      .addCase(getPartnerStripeAccount.fulfilled, (state, action) => {
        state.isSuccess = true
        state.stripeAccount = action.payload
      })
      .addCase(getPartnerStripeAccount.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
      })
  },
})

export const { reset, logout, resetResendEmailState, resetVerificationState, resetPurchaseState } = subscriptionSlice.actions

export default subscriptionSlice.reducer
