import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import paymentsService from './paymentsService'

const initialState = {
  paymentStatus: null,
  errorMessage: null,
  isLoading: false,
  isSuccess: false,
  paymentDetails: null,
  subscriptionDetails: null,
  isError: null,
  products: null,
}

// Async thunk for processing payment
export const processPayment = createAsyncThunk('payment/processPayment', async (paymentData, { rejectWithValue }) => {
  console.log('Processing payment...')
  console.log(paymentData, 'did it work?')
  try {
    const response = await paymentsService.processPayment(paymentData)
    return response
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message)
  }
})

// *get available products
export const getProducts = createAsyncThunk('payment/getProducts', async (_, thunkAPI) => {
  try {
    return await paymentsService.getProducts()
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    paymentSuccess: (state) => {
      state.paymentStatus = 'success'
    },
    paymentFailure: (state, action) => {
      state.paymentStatus = 'failure'
      state.errorMessage = action.payload
    },
    resetPaymentStatus: (state) => {
      state.paymentStatus = null
      state.errorMessage = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processPayment.pending, (state) => {
        state.isLoading = true
        state.isError = null
        state.isSuccess = false
      })
      .addCase(processPayment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.paymentDetails = action.payload.payment
        state.subscriptionDetails = action.payload.subscription
      })
      .addCase(processPayment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = action.payload
      })
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.isLoading = false

      state.products = action.payload // set the products to the fetched data
    })
    builder.addCase(getProducts.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
  },
})

export const { paymentSuccess, paymentFailure, resetPaymentStatus } = paymentsSlice.actions
export default paymentsSlice.reducer
